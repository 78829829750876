import { Injectable } from "@angular/core";
import { dispatchError } from "src/app/core/dispatchError";

class LocalItemCache {
  private cache = {};

  async add(url: string) {
    this.cache[url] = await fetch(url);
  }

  async addAll(urls: string[]) {
    for (let url of urls) {
      await this.add(url);
    }
  }

  async match(url: string) {
    return this.cache[url];
  }

  async delete(url: string) {
    delete this.cache[url];
  }

  async keys() {
    return Object.keys(this.cache).map((key) => this.cache[key]);
  }
}

class LocalCache {
  private caches = {};

  async open(name: string) {
    if (this.caches[name]) {
      return this.caches[name];
    } else {
      this.caches[name] = new LocalItemCache();
      return this.caches[name];
    }
  }

  async delete(name: string) {
    delete this.caches[name];
  }

  async keys() {
    return Object.keys(this.caches);
  }
}

@Injectable({
  providedIn: "root",
})
export class CacheService {
  private localCache = new LocalCache();

  constructor() {}

  get cache() {
    if ("caches" in window) {
      return window.caches;
    } else {
      return this.localCache;
    }
  }

  private open(cacheName: string, operation: string) {
    try {
      return this.cache.open(cacheName);
    } catch (err) {
      dispatchError(`IMAGE_CACHE_OPEN_ERROR`, { operation, err });
      return this.localCache.open(cacheName);
    }
  }

  async addToCache(cacheName: string, url: string): Promise<void> {
    try {
      const cache = await this.open(cacheName, "add");
      const cachedResponse = await cache.match(url);
      if (!cachedResponse) {
        await cache.add(url);
      }
    } catch (err) {
      dispatchError(`IMAGE_CACHE_ADD_ERROR`, { cacheName, url, err });
      throw err;
    }
  }

  async getFromCache(cacheName: string, url: string): Promise<Response | null> {
    const cache = await this.open(cacheName, "get");
    const cachedResponse = await cache.match(url);
    return cachedResponse;
  }

  async removeFromCache(cacheName: string, url: string): Promise<void> {
    const cache = await this.open(cacheName, "remove");
    await cache.delete(url);
  }

  async clearCache(cacheName: string): Promise<void> {
    await this.cache.delete(cacheName);
  }

  async getCacheKeys(cacheName: string): Promise<string[]> {
    const cache = await this.open(cacheName, "getKeys");
    return (await cache.keys()).map((request) => request.url);
  }

  async addAll(cacheName: string, urls: string[]) {
    try {
      const cache = await this.open(cacheName, "addAll");
      return await cache.addAll(urls);
    } catch (err) {
      dispatchError(`IMAGE_CACHE_ADDALL_ERROR`, { cacheName, err });
      throw err;
    }
  }

  async cleanOldCaches(currentCacheName: string): Promise<boolean> {
    const cacheNames = await this.cache.keys();
    for (const cacheName of cacheNames) {
      if (cacheName !== currentCacheName) {
        await this.clearCache(cacheName);
        return true;
      }
    }
  }
}
