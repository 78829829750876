import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { KeycloakAngularModule, KeycloakService } from "keycloak-angular";
import { CookieService } from "ngx-cookie-service";
import { initializer } from "./app-init";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthModule } from "./auth/auth.module";
import { PagesModule } from "./pages/pages.module";
import { ApiEndpointInterceptor } from "./shared/interceptors/api-endpoint.interceptor";
import { UrlLocaleInterceptor } from "./shared/interceptors/url-locale.interceptor";
import { PrimeNgModule } from "./shared/primeng/primeng.module";
import { CustomErrorHandler } from "./shared/services/error-handler";
import { LocalStorageService } from "./shared/services/local-storage.service";
import { LoginParamsService } from "./shared/services/login-params.service";
import { SharedModule } from "./shared/shared.module";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import localeAr from "@angular/common/locales/ar";
import localeEn from "@angular/common/locales/en";
import localeEs from "@angular/common/locales/es";
import localeHi from "@angular/common/locales/hi";
import localeUr from "@angular/common/locales/ur";
import localeId from "@angular/common/locales/id";
import localeZh from "@angular/common/locales/zh";
import { HeadersInterceptor } from "./shared/interceptors/headers.interceptor";
import localeDe from "@angular/common/locales/de";
import { IdiomaService } from "./shared/services/idioma.service";
import { CustomTranslateLoader } from "./shared/services/custom-translate-loader.service";
import { ImagemCacheService } from "./shared/services/imagem-cache.service";
import { AuthService } from "./shared/services/auth.service";

registerLocaleData(localePt);
registerLocaleData(localeAr);
registerLocaleData(localeEn);
registerLocaleData(localeEs);
registerLocaleData(localeHi);
registerLocaleData(localeUr);
registerLocaleData(localeDe);
registerLocaleData(localeId);
registerLocaleData(localeZh);

@NgModule({
  declarations: [AppComponent],
  imports: [
    KeycloakAngularModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule,
    PagesModule,
    SharedModule,
    PrimeNgModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiEndpointInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: UrlLocaleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HeadersInterceptor, multi: true },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [
        KeycloakService,
        LocalStorageService,
        LoginParamsService,
        ImagemCacheService,
        AuthService,
      ],
    },
    {
      provide: LOCALE_ID,
      deps: [IdiomaService],
      useFactory: (idiomaService: IdiomaService) => idiomaService.getBaseLang(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
