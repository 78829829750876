import { initNewRelic } from "./core/initNewRelic";
import { KeycloakService } from "keycloak-angular";
import { environment } from "../environments/environment";
import { LocalStorageService } from "./shared/services/local-storage.service";
import { SessionStorageValues } from "./shared/enums/session-storage-values.enum";
import { SessionStorageKeys } from "./shared/enums/session-storage-keys.enum";
import { LoginParamsService } from "./shared/services/login-params.service";
import { ImagemCacheService } from "./shared/services/imagem-cache.service";
import { AuthService } from "./shared/services/auth.service";
import { initHotjar } from "./core/initHotjar";

export function initializer(
  keycloak: KeycloakService,
  localStorageService: LocalStorageService,
  loginParams: LoginParamsService,
  imagemCacheService: ImagemCacheService,
  authService: AuthService
): () => Promise<any> {
  return (): Promise<any> => {
    initNewRelic();
    initHotjar();
    return new Promise(async (resolve, reject) => {
      try {
        await keycloak.init(environment.keycloakInit);

        //Verificar se a variavel esta preenchida, se sim desloga o ultimo usuario
        //e solicita login novamente (app.component.ts > doBeforeUnload)
        if (
          !environment.flEmbedded &&
          SessionStorageValues.SIM ===
            localStorageService.getAndRemoveItem(
              SessionStorageKeys.DESLOGAR_USUARIO
            )
        ) {
          const urlBase = document.getElementsByTagName("base")[0].href;
          await keycloak.logout(urlBase);
        }

        if (!environment.dev) {
          localStorage.clear();
          sessionStorage.clear();
        }
        loginParams.saveParamsOnStorage();

        const w: any = window;
        if (w?.newrelic?.setUserId) {
          const matricula =
            keycloak.getKeycloakInstance().tokenParsed.preferred_username;
          w?.newrelic?.setUserId(matricula);
          w?.newrelic?.setCustomAttribute("matricula", matricula);
        }

        try {
          await imagemCacheService.init();
        } catch (err) {
          authService.handleLogout();
          alert(err.message);
          throw err;
        }

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };
}
