import { ClienteEnum } from "../shared/enums/Cliente.enum";
import { appendScript } from "../shared/utils/appendScript.util";
import { environment } from "src/environments/environment";

export const initHotjar = () => {
  const ALLOWED_CLIENTS = [ClienteEnum.SME];
  const isAllowed = ALLOWED_CLIENTS.includes(
    environment.cliente as ClienteEnum
  );
  if (isAllowed) {
    appendScript(`assets/hotjar.js`, "head");
  }
};
