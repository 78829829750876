import { wait } from "./../../shared/utils/wait.util";
import {
  Component,
  OnInit,
  HostListener,
  HostBinding,
  OnDestroy,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { AuthService } from "src/app/shared/services/auth.service";
import { TabelaAscii } from "src/app/shared/enums/tabela-ascii.enum";
import { ConfiguracaoTesteTO } from "src/app/shared/tos/configuracao-teste.to";
import { TesteService } from "src/app/shared/api-services/teste.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { SessionStorageService } from "src/app/shared/services/session-storage.service";
import { ConfiguracaoTesteApiService } from "src/app/shared/api-services/configuracao-teste.api-service";
import { DefaultPage } from "../default-page";
import { SessionStorageKeys } from "src/app/shared/enums/session-storage-keys.enum";
import { SessionStorageValues } from "src/app/shared/enums/session-storage-values.enum";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { InactiveTimerService } from "src/app/shared/services/inactive-timer.service";
import { NotificationService } from "src/app/shared/services/notification.service";
import { environment } from "src/environments/environment";
import { TermoConsentimentoComponent } from "./termo-consentimento/termo-consentimento.component";
import { ImagemCacheService } from "src/app/shared/services/imagem-cache.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BooleanEnum } from "src/app/shared/enums/boolean.enum";
import { TipoDispositivoService } from "src/app/shared/services/tipo-dispositivo.service";
import { LogService } from "src/app/shared/services/log.service";
import { Subscription } from "rxjs";
import { ClienteEnum } from "src/app/shared/enums/Cliente.enum";
import { ZoomBlockerService } from "src/app/shared/services/zoom-blocker.service";
import { RespostaPerguntaMatutinidadeV2TO } from "src/app/shared/tos/request-resposta-pergunta-matutinidade.to";

@Component({
  selector: "app-introducao",
  templateUrl: "./introducao.component.html",
  styleUrls: ["./introducao.component.scss"],
})
export class IntroducaoComponent
  extends DefaultPage
  implements OnInit, OnDestroy
{
  isSimulacao: boolean;

  termoComponent: TermoConsentimentoComponent;

  imagens: Array<string>;
  imagensCorretas: Array<string>;
  imagensIncorretas: Array<string>;
  nomeUsuario: string;
  elem;
  nomeImagemInstrucao: string = "instrucoes_prontos_2.gif";
  exibirImagemInstrucao = true;
  isImagensCacheCarregadas = false;
  sub: Subscription;
  podeRealizarTeste = true;

  constructor(
    private readonly routerService: RouterService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService,
    private readonly configuracaoTesteApiService: ConfiguracaoTesteApiService,
    private readonly keycloakAngularService: KeycloakService,
    private translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly testeService: TesteService,
    private inactiveTimerService: InactiveTimerService,
    private notificationService: NotificationService,
    public imagemCacheService: ImagemCacheService,
    private tipoDispositivoService: TipoDispositivoService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private logService: LogService,
    private zoomBlockerService: ZoomBlockerService
  ) {
    super();
  }

  configuracaoTeste: ConfiguracaoTesteTO;

  @HostBinding("class")
  get travarTela() {
    return this.podeRealizarTeste ? "" : "travar-tela";
  }

  @HostBinding("class")
  get simulador() {
    return this.isSimulacao ? "--simulacao" : "";
  }

  ngOnInit() {
    this.isSimulacao = this.activeRoute.snapshot.data["isSimulacao"];
    this.logService.append("Acessou introducao");
    if (this.tipoDispositivoService.isDesktop() == false) {
      this.nomeImagemInstrucao = "instrucao_tablet.gif";
    }
    this.definirDadosDeAcordoProfile();
    this.elem = document.documentElement;
    this.loading = false;
    this.exibirImagemInstrucao = !environment.flEmbedded;
    this.carregarDadosTeste();
  }

  onTermoAtivate(event) {
    this.termoComponent = event;
    this.termoComponent.show();

    this.termoComponent.ignorar.subscribe(() => this.handleValidacaoInicial());
    this.termoComponent.concordar.subscribe(() =>
      this.handleValidacaoInicial()
    );
    this.termoComponent.descordar.subscribe(() => this.descordarTermo());
  }

  private exibirMensagemZoomAtivado() {
    this.logService.append("Exibido mensagem zoom");
    return new Promise((res, rej) => {
      this.notificationService.notify({
        acceptLabel: this.translateService.instant("sim"),
        handler: () => {
          res(false);
        },
        message: this.translateService.instant("introducao.erroZoomAplicado"),
      });
    });
  }

  private exibirNotificacaoExpediente() {
    this.logService.append("Exibido notificacao expediente");
    const isValourec = environment.cliente == ClienteEnum.VALLOUREC;
    const isTernium = environment.cliente == ClienteEnum.TERNIUM;
    const isVale = environment.cliente == ClienteEnum.VALE;
    return new Promise((res, rej) => {
      this.notificationService.notify({
        rejectVisible: true,
        acceptLabel: this.translateService.instant("sim"),
        handler: () => {
          res(true);
        },
        handlerReject: () => {
          this.exibirNotificacaoNaoPodeRealizarTeste();
          rej(false);
        },
        message: this.translateService.instant(
          isValourec
            ? "notificacao.perguntaExpedienteVallourec"
            : isTernium
            ? "notificacao.perguntaExpedienteTernium"
            : isVale
            ? "notificacao.perguntaExpedienteVale"
            : "notificacao.perguntaExpediente"
        ),
      });
    });
  }

  private exibirNotificacaoTesteIncompleto() {
    this.logService.append("Exibido notificacao teste incompleto");
    return new Promise((res, rej) => {
      this.inactiveTimerService.start(async () => rej(false), 60000 * 1); // tempo de 1 minuto para aceitar
      this.notificationService.notify({
        message: this.translateService.instant(
          "notificacao.exibirNotificacaoTesteIncompleto"
        ),
        handler: () => {
          this.inactiveTimerService.stop();
          res(true);
        },
      });
    });
  }

  private exibirNotificacaoNaoPodeRealizarTeste() {
    this.logService.append("Exibido mensagem nao pode realizar teste");
    this.podeRealizarTeste = false;
    setTimeout(() => {
      this.notificationService.notify({
        message: this.translateService.instant(
          "notificacao.rejeitarExpediente"
        ),
        handler: this.authService.handleLogout.bind(this.authService),
      });
    }, 200);
  }

  definirDadosDeAcordoProfile() {
    this.nomeUsuario =
      this.keycloakAngularService.getKeycloakInstance().tokenParsed[
        "given_name"
      ];
  }

  carregarDadosTeste() {
    this.configuracaoTeste = this.sessionStorageService.getItem(
      SessionStorageKeys.CONFIGURACAO
    );
    this.imagensCorretas = new Array<string>();
    this.imagensIncorretas = new Array<string>();

    this.configuracaoTesteApiService
      .getConfiguracaoTesteTO(this.isSimulacao)
      .subscribe(
        (configuracao) => {
          this.saveConfigs(configuracao);
          if (
            !this.isSimulacao &&
            configuracao.flExibeTermoConsentimento == BooleanEnum.SIM
          ) {
            this.logService.append("Exibir termo");
            this.router.navigate(["termo"], {
              relativeTo: this.activeRoute,
              skipLocationChange: true,
            });
          } else {
            this.handleValidacaoInicial();
          }
        },
        (error) => {
          throw error;
        }
      );
  }

  saveConfigs(configuracao) {
    //Marcar para deslogar o usuario quando iniciar
    this.localStorageService.setItem<SessionStorageValues>(
      SessionStorageKeys.DESLOGAR_USUARIO,
      environment.dev ? SessionStorageValues.NAO : SessionStorageValues.SIM
    );
    this.configuracaoTeste = configuracao;
    this.sessionStorageService.setItem(
      SessionStorageKeys.CONFIGURACAO,
      configuracao
    );
  }

  descordarTermo() {
    this.authService.handleLogout();
  }

  async shouldZoomDisabled() {
    if (this.zoomBlockerService.verifyDimensions() == false) {
      await this.exibirMensagemZoomAtivado();
      await wait(200);
      return false;
    }
    return true;
  }

  async exibirMensagens() {
    try {
      await this.shouldZoomDisabled();

      if (this.configuracaoTeste.flExibeOrientacaoInicial === "SIM") {
        await this.exibirNotificacaoExpediente();
        await wait(200); //precisa esperar fechar o modal para abrir o proximo
      }
      if (this.configuracaoTeste.flExibeMensagem3TestesIncompletos === "SIM") {
        await this.exibirNotificacaoTesteIncompleto();
        await wait(200);
      }
    } catch (err) {
      console.log("Parar de exibir mensagens");
    }
  }

  async handleValidacaoInicial() {
    this.definirFigurasCertasErradas();
    this.loading = true;

    if (!this.isSimulacao) {
      await this.exibirMensagens();
      this.inactiveTimerService.start(() => this.upload());
    }
  }

  async upload() {
    // const processaTesteTO: ProcessaTesteTO = {
    //   idUsuario: this.configuracaoTeste.idUsuario,
    //   idTeste: this.configuracaoTeste.idTesteExecutado,
    //   alvos: [],
    //   respostaPerguntas: [],
    //   flEnviaRetorno: false
    // };
    // await this.testeService.upload(processaTesteTO).toPromise();
  }

  definirFigurasCertasErradas() {
    let quantidadeImagemCerta = this.configuracaoTeste.referencia.qtAlvosCertos;
    const totalImagens: number =
      this.configuracaoTeste.referencia.qtAlvosCertos +
      this.configuracaoTeste.referencia.qtAlvosErrados;
    let imgCerta: number = -1;
    let pos: number = 0;
    this.imagens = this.configuracaoTeste.referencia.dsAlvos.split(",");

    while (quantidadeImagemCerta > 0) {
      let i = 0;

      //evitar selecionar a mesma imagem mais de uma vez aleatoriamente
      while (i != -1) {
        imgCerta = Math.floor(Math.random() * totalImagens + 1);
        pos = +imgCerta - 1;

        if (this.imagensCorretas.indexOf(this.imagens[pos]) == -1) {
          //preenchendo imagens certas
          this.imagensCorretas.push(this.imagens[pos]);

          i = -1;
        }
      }

      quantidadeImagemCerta = quantidadeImagemCerta - 1;
    }

    // Preenchendo imagens erradas
    for (let y = 0; y < this.imagens.length; y++) {
      if (this.imagensCorretas[0].indexOf(this.imagens[y]) == -1) {
        this.imagensIncorretas.push(this.imagens[y]);
      }
    }

    this.sessionStorageService.setItem(
      SessionStorageKeys.IMAGENS_INCORRETAS,
      this.imagensIncorretas
    );
    this.sessionStorageService.setItem(
      SessionStorageKeys.IMAGENS_CORRETAS,
      this.imagensCorretas
    );
    this.sessionStorageService.setItem(
      SessionStorageKeys.CONFIGURACAO_TESTE,
      this.configuracaoTeste
    );

    // if(this.configuracaoTeste.referencia.qtAlvosErrados > 1){
    //   var botao = document.querySelector('#botaoIntroducao');
    //   botao.setAttribute("style", "margin-top: 6vh !important;");
    // } else {
    //   var botao = document.querySelector('#figuraIncorreta');
    //   botao.setAttribute("style", "margin-left: 30px; !important;");
    // }
  }

  async navegar() {
    if (!(await this.verificarImagensCacheCarregadas())) {
      return;
    }
    if ((await this.shouldZoomDisabled()) == false) {
      return;
    }
    this.inactiveTimerService.stop();
    this.openFullscreen();
    if (this.isSimulacao) {
      this.logService.append("Ir para simulacao");
      this.routerService.toTesteSimulacao();
    } else {
      this.logService.append("Ir para teste");
      this.routerService.toTeste();
    }
  }

  openFullscreen() {
    if (!document.fullscreenEnabled) {
      return;
    }
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
  }

  @HostListener("document:keypress", ["$event"])
  public onKey(event: KeyboardEvent) {
    const tecladoNumerico = event.keyCode || event.which;

    switch (tecladoNumerico) {
      case TabelaAscii.teclaEnter: {
        if (this.isTestePronto()) {
          this.navegar();
        }
        break;
      }
      case TabelaAscii.teclaEspaco: {
        if (this.isTestePronto()) {
          this.navegar();
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  isTestePronto() {
    return this.loading;
  }

  async verificarImagensCacheCarregadas() {
    this.isImagensCacheCarregadas =
      await this.imagemCacheService.verificarTodasAsImagensCarregadas();
    if (this.isImagensCacheCarregadas) return true;
    this.notificationService.notifyError({
      message: this.translateService.instant("erroImagensCache"),
      handler: () => this.authService.handleLogout(),
    });
    return false;
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
